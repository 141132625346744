import { Fragment } from "react"
import { Link } from "react-router-dom"
import Table from "../Table"
import Header from "../Header"
import SchoolSidebar from "../Sidebar/schoolSideBar";
import Footer from "../Footer"

const SchoolAdmissionsContainer=(props)=>{
    const {defaultMyapplicationColumns,rows }=props

    return(
        <Fragment>
        <div className='wrapper  sidebar-mini layout-fixed'>
            <Header/>
            <SchoolSidebar/>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Admissions</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">admissions</li>
                                </ol>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-sm-12">

                                <Table cols={defaultMyapplicationColumns} dbRows={rows} tableType="advanced"/>
                        
                            </div>
                        </div>        
                        
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    </Fragment>
    )
}

export default SchoolAdmissionsContainer
