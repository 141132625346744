import { Fragment } from "react"
import { Link } from "react-router-dom"
import Footer from "../Footer"
import Header from "../Header"
import Sidebar from "../Sidebar"
import MediaUpload from "../MediaUpload"
import Schools from "../../components/Schools"
import Classes from "../../components/Classes"
import { useLocation} from "react-router-dom"
import Consent from "../../components/ApplicationFee"



const ApplicationForm=(props)=>{
    const{handleMediaChange, handleSubmit, handleClear, selectedClass}=props
    const location = useLocation()
    let application_type = location.pathname.split('/')[2]
      const fileTypes = ["JPEG", "JPG", "PNG"];
      const mediaLabel = "Upload Learner Results"
  
    return(
    <Fragment>
        <div className='wrapper  sidebar-mini layout-fixed'>
            <Header/>
            <Sidebar/>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    <form onSubmit={handleSubmit}>

                    <div className="row mb-2">

                        <div className="col-sm-12 card sch-card">
                            <div className="card-header">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                <h5 className="m-0"><span className="cap-header">{application_type}</span> application</h5>
                                </div>
                                <div className="col-sm-6">
                                <button className="btn btn-success float-right"><i className="fa fa-save"></i> Apply to {application_type} institution</button>

                                </div>
                             </div>
                            </div>

                            <div className="card-body">
                            <div className="row mb-2">
                            <div className="col-sm-6">
                                <div className="form-group main-form main-form-first" >
                                <label htmlFor="customer">Institution name</label>
                                <Schools />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group main-form main-form-first" >
                                <label htmlFor="customer">{application_type==='university' || application_type==='tertiary'?("Select course"):("Select class")}</label>
                                <Classes/>
                                {localStorage.setItem('class_id', selectedClass)}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12">
                            <label htmlFor="results">Academic results</label>
                                <MediaUpload fileTypes={fileTypes} mediaLabel={mediaLabel} handleMediaChange={handleMediaChange} />     
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12">
                                <Consent />

                            </div>
                        </div>
                            </div>
                        </div>
                     </div>


                            


                        </form>
                 </div>       
            </div>
           </div>
           <Footer/>
    </div>

    </Fragment>
    )
}
export default ApplicationForm
