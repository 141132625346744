import {Fragment} from "react";
import {Link} from "react-router-dom";
import { user_type } from "../../config/constants";

const Header =(props)=> {
    let home_link = <Link to="/home" className="nav-link">Home</Link>
    let header_link =   ""
    let header_link_two = <Link to="/myapplications" className="nav-link">Applications</Link>;
    let header_link_three =""
     
    if(user_type=="sch-admin"){
           home_link = <Link to="/school-portal" className="nav-link">Home</Link>
           header_link = <Link to="" className="nav-link">Institutions</Link>;
           header_link_two = <Link to="" className="nav-link">Admissions</Link>;
           header_link_three = <Link to="" className="nav-link">Payments</Link>;
            
         }
    else if(user_type=="school"){
        home_link = <Link to="/school-portal" className="nav-link">Home</Link>
        header_link=  <Link to="/school/application" className="nav-link">Learner applications</Link>
        header_link_two = <Link to="/school/admissions" className="nav-link">Admissions</Link>;
        header_link_three =  <li className="nav-item d-none d-sm-inline-block">
        {/* <Link to="" className="nav-link">Learners</Link> */}
    </li>
    }
    return(
        <Fragment>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a href="#" className="nav-link" data-widget="pushmenu"  role="button"><i
                            className="fas fa-bars"></i>
                        </a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        {home_link}
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        {header_link}
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        {header_link_two}
                    </li>
                    {
                        header_link_three
                    }
 
                </ul>

                <ul className="navbar-nav ml-auto">
                    {/* <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-bell"></i>
                            <span className="badge badge-warning navbar-badge">15</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-item dropdown-header">15 Notifications</span>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item">
                                <i className="fas fa-envelope mr-2"></i> 4 new messages
                                <span className="float-right text-muted text-sm">3 mins</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item">
                                <i className="fas fa-users mr-2"></i> 8 friend requests
                                <span className="float-right text-muted text-sm">12 hours</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item">
                                <i className="fas fa-file mr-2"></i> 3 new reports
                                <span className="float-right text-muted text-sm">2 days</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            <Link to="#" className="dropdown-item dropdown-footer">See All Notifications</Link>
                        </div>
                    </li> */}
{/* 
                    <li className="nav-item">
                        <Link className="nav-link" data-widget="fullscreen" to="#" role="button">
                            <i className="fas fa-expand-arrows-alt"></i>
                        </Link>
                    </li> */}
                    {/* <li className="nav-item">
                        <Link  to="#" className="nav-link" data-widget="control-sidebar" data-slide="true" role="button">
                            <i className="fas fa-th-large"></i>
                        </Link>
                    </li> */}

                    <li className="nav-item dropdown">
                        <a href="#" className="nav-link"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-user-circle   fa-2x"></i>

                        </a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <Link to="/profile/general" className="dropdown-item">Profile</Link>
                        <Link to="#" className="dropdown-item" >Settings</Link>
                        <div className="dropdown-divider"></div>
                        <Link to="/logout" className="dropdown-item"><i className="fas fa-sign-out-alt"></i> Sign out</Link>                        </div>
                    </li>

                </ul>
            </nav>
        </Fragment>
    )

}

export default Header
