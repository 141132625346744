import React, {Component} from "react";
import RegistrationForm from "../../containers/RegistrationForm";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {registerUser} from "../../actions/userActions";

class Register extends Component{
    constructor(props) {
        super(props);
        this.state={
            first_name:'',
            last_name:'',
            email:'',
            password:''
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isUserRegistered === true){
            const {history} = this.props
            history.push('/')
        }
    }

    handleSubmit = event =>{
        event.preventDefault();
        const {first_name,last_name,email,password} = this.state
        const user = {
            first_name,last_name,email,password
        }
        const {register}=this.props
        register(user)
    }

    handleChange = event =>{
        const {name, value}=event.target
        this.setState({[name]:value})
    }


    render() {
        return(
            <RegistrationForm
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
            />
        )
    }
}

Register.propTypes = {
    register: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}
 const mapStateToProps =state=>({
     ...state.user
 })

export default connect(mapStateToProps, {register:registerUser})(Register);
