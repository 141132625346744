import { Component } from "react";
import { connect } from "react-redux";
import {getEmergencyContacts, addEmergencyContacts} from "../../actions/profileActions"
import EmergencyContacts from "../../containers/Profile/emergencyContacts";

class EmergencyProfile extends Component{

    constructor(props){
        super(props)
        this.state={ 
            sick:'',
            sickness_type:'N/A',
            learners_physcian:'N/A',
            physcian_tel:'N/A',
            physcian_address:'N/A',
            postal_code:'N/A',
            allergies:'N/A',
            learnerEmergencyContacts:[[]],
        }
    }

    componentDidMount(){
        const {getEmergencyContacts} =this.props
        getEmergencyContacts()
    }

    static getDerivedStateFromProps(nextProps){
        const {learnerEmergencyContacts} = nextProps
        return{learnerEmergencyContacts}
    }

    
    handleChangeOnModal = event =>{
        const {name, value} = event.target
        this.setState({[name]:value})
        console.log(this.state)
    }

    handleSubmit= event=>{
        event.preventDefault()
        const {addEmergencyContacts} =this.props
        const {sick,sickness_type,learners_physcian,
            physcian_tel, physcian_address,
            postal_code, allergies}=this.state
        const medicalData={
            medical:{
                sick,
                sickness_type,
                learners_physcian: learners_physcian,
                physcian_tel: physcian_tel,
                physcian_address: physcian_address,
                postal_code: postal_code,
                allergies: allergies


               },

        }
        addEmergencyContacts(medicalData)
}

    render(){
        const { sick,learnerEmergencyContacts} = this.state
        return(
            <EmergencyContacts

                sick={sick}
                dbRows={learnerEmergencyContacts}
                handleChangeOnModal={this.handleChangeOnModal}
                handleSubmit={this.handleSubmit}
            />
        )
    }
}

const mapStateToProps = state =>({
    ...state.emergencyContacts
})
const EmergencyContactsProfile = connect(mapStateToProps, {getEmergencyContacts, addEmergencyContacts})(EmergencyProfile)
export default EmergencyContactsProfile
