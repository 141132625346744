import { Component } from "react";
import { connect } from "react-redux";
import FamilyContainer from "../../containers/Profile/Family"
import {getFamilyInfo} from "../../actions/profileActions"

class FamilyDetails extends Component{

    constructor(props){
        super(props)
        this.state={
            familyMembersDetails:[],
            memberAdded:false
        }
    }

    componentDidMount(){
        const {getFamilyInfo} = this.props
        getFamilyInfo()

    }


    static getDerivedStateFromProps(nextProps, prevState){
        const {familyMembersDetails}=nextProps

            return { familyMembersDetails}
 
    }
    

    render(){
        const {familyMembersDetails} = this.state
        return(
            <FamilyContainer
                familyDetails={familyMembersDetails}
                handleSubmitOnModal={this.handleSubmit}
                handleChangeOnModal={this.handleChange}
            />
        )
    }
}

const mapStateToProps = state =>({
    ...state.family
})
const FamilyProfile = connect(mapStateToProps, {getFamilyInfo})(FamilyDetails)
export default FamilyProfile
