import {Fragment} from "react";
import {Link, useLocation} from "react-router-dom";
import brandLogoWhite from "../../static/images/brandLogoWhite.png"
import { currentUser } from "../../config/constants";

const Sidebar=(props)=>{
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return(
        <Fragment>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <div className="brand-link">
                    <img src={brandLogoWhite} alt="mukisa lms Logo" className="brand-image"
                         style={{opacity: .8}}/>
                    <span className="brand-text font-weight-light"> &nbsp;</span>
                </div>

                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="info">
                            <h5 className="company-name"> {currentUser}</h5>
                        </div>
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li className="nav-item">
                                <a href="/home" className={splitLocation[1] === "home" ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        Dashboard
                                    </p>
                                </a>
                            </li>
                            <li className="nav-item menu-open">
                                <a href="#" className={splitLocation[1] === "admin-portal" ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon fab fa-adn"></i>                                    <p>
                                        Applications
                                        <i className="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                            <li className="nav-item">
                                <Link to="/application/elementary" className="nav-link">
                                    <i className="nav-icon fa fa-child"></i>                                    <p>
                                    Elementary Schools
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/application/primary" className="nav-link">
                                    <i className="nav-icon fa fa-child"></i>                                    <p>
                                    Primary Schools
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/application/secondary" className="nav-link">
                                    <i className="nav-icon fa fa-child"></i>                                    <p>
                                    Secondary Schools                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/application/tertiary" className="nav-link">
                                    <i className="nav-icon fas fa-university"></i>                                    <p>
                                    Tertiary Institutions
                                        <span className="right badge badge-danger">New</span>
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/application/university" className="nav-link">
                                    <i className="nav-icon fa fa-graduation-cap"></i>                                    <p>
                                    Universities
                                        <span className="right badge badge-danger">New</span>
                                    </p>
                                </Link>
                            </li>
                          </ul>
                        </li>
                            <li className="nav-item">
                                <Link to="/myapplications" className={splitLocation[1] === "myapplications" ? "nav-link active" : "nav-link"}>
                                    <i className="nav-icon far fa-image"></i>
                                    <p>
                                        My applications
                                    </p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="/myadmissions" className="nav-link">
                                    <i className="nav-icon fab fa-resolving"></i>                                    <p>
                                        Admission Results
                                    </p>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a href="" className="nav-link">
                                    <i className="nav-icon fas fa-money-check"></i>                                    <p>
                                        Transaction Details
                                    </p>
                                </a>
                            </li> */}

                        </ul>
                    </nav>
                </div>
            </aside>
        </Fragment>
    )
}
export default Sidebar
