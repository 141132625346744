import { useState } from 'react';

const MediaModal = ({ mediaUrl, title, onClose, onSave, onDelete }) => {
    const [isZoomed, setIsZoomed] = useState(false); // Initial zoom state is false

    const toggleZoom = () => {
        setIsZoomed(!isZoomed); // Toggle zoom state when clicked
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h3>{title}</h3> {/* Display the title here */}
                    <button className="close-btn" onClick={onClose}>×</button>
                </div>

                {/* Display media content */}
                <div className="modal-body" onClick={toggleZoom} style={{ cursor: 'pointer' }}>
                    {/* Overlay for zoom indication */}
                    <div className="zoom-indicator">
                        <i className={`fas ${isZoomed ? 'fa-search-minus' : 'fa-search-plus'}`}></i> 
                        {isZoomed ? " Click to zoom out" : " Click to zoom in"}
                    </div>

                    {mediaUrl.includes('.pdf') ? (
                        <embed src={mediaUrl} type="application/pdf" width="100%" height="500px" />
                    ) : (
                        <img src={mediaUrl} alt="Media" className={isZoomed ? 'zoomed-image' : ''} />
                    )}
                </div>

                <div className="modal-footer-separator"></div>

                {/* Buttons at the bottom */}
                <div className="modal-buttons">
                    <button className="btn btn-danger reject" onClick={onDelete}>Decline Student</button>
                    <button className="btn btn-success" onClick={onSave}>Accept learner</button>
                </div>
            </div>
        </div>
    );
};

export default MediaModal;
