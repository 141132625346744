import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import LoginForm from "../../containers/LoginForm/LoginForm";
import {loginUser} from "../../actions/userActions";

class Login extends Component{
    constructor(props) {
        super(props);
        this.state={
            email:'',
            password:''
        }
    }
    componentWillReceiveProps(nextProps){
        const {history} = this.props
        const path = this.handleRedirection(nextProps.userType)
        history.push(path)
    }
    handleRedirection = userType =>{
        let path = '/home';
        if (userType === 'school') {
            path = '/school-portal'
        }
        else if( path === 'sch-admin'){
            path = '/school-portal'
        }
        return path
    }

    handleSubmit = event =>{
        event.preventDefault()
        const {signIn}=this.props
        const {email, password} = this.state
        const user_data = {
            email,
            password,
        }
        signIn(user_data)
    }
    handleChange=event=>{
        const{name,value}=event.target
        this.setState({[name]:value})
    }

    render(){
        return(
            <LoginForm
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
            />
        )
    }
}
Login.propTypes={
    signIn: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

const mapStateToProps=state=>({
    ...state.user
})

export default connect(mapStateToProps,{signIn:loginUser})(Login);
