import React, {PureComponent} from "react";
import GoogleLogin from "react-google-login";

class GoogleButton extends PureComponent{
    constructor() {
        super();
    }

    handleGoogleResponse = (response)=>{
        // console.log(response)
    }
    
    render() {
        
        return(
            <GoogleLogin
                clientId="341130765085-1fh3g45of8vh96n68gr7hf7nrq3o4dst.apps.googleusercontent.com"
                onSuccess={this.handleGoogleResponse}
                onFailure=""
                className="google"
                >
                <span> Sign in with Google </span>
            </GoogleLogin>
        )
    }
}

export default GoogleButton;