import { Fragment } from "react"
import { Link } from "react-router-dom"
import Header from "../Header"
import Footer from "../Footer"
import Sidebar from "../Sidebar"
import BasicInfo from "../../components/Profile/basicInfo"
import ProfileNav from "./ProfileNav"

const ProfileContainer =(props)=>{
    const {currentUser,
        first_name,
        last_name,
        email,
        dob, 
        phone_number,
        gender} = props
    return(
        <Fragment>
            <div className='wrapper  sidebar-mini layout-fixed'>
                <Header/>
                <Sidebar currentUser={currentUser}/>
                <div className="content-wrapper">
                 <div className="content-header">
                    <div className="container-fluid">


                      <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Profile</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">profile</li>
                                </ol>
                            </div>
                        </div>

                        <div className="row mb-2">

                        <ProfileNav />
</div>

                        <div className="row mb-2">
                            <div className="col-sm-12 card">
                                <div className="card-header">
                                    <div className="row mb-2">
                                        <div className="col-sm-6 profile-panel-padding">
                                        <h5>Learner's Basic Information</h5>
                                        </div>
                                        <div className="col-sm-6"><button className="btn btn-success float-right" disabled={first_name && dob && gender ? true : false} data-toggle="modal" data-target="#basicProfile">Add basic Information</button></div>
                                    </div>
                                </div>
                                <div className="card-body padding-bottom">
                                <BasicInfo/> 

                                     <div className="row mb-2">
                                    <div className="col-sm-4">
                                    <label htmlFor="customer">First Name</label>
                                    <input type="text" name="first_name" value={first_name} disabled className="form-control millman-input"  placeholder="First name"/>
                                    </div>

                                    <div className="col-sm-4">
                                    <label htmlFor="customer">Last Name</label>
                                    <input type="text" name="last_name" value={last_name} disabled className="form-control millman-input"  placeholder="Last name"/>
                                    </div>


                                    <div className="col-sm-4">
                                    <label htmlFor="customer">Email address</label>
                                    <input type="text" name="email" value={email} disabled className="form-control millman-input"  placeholder="Email address"/>
                                    </div>
                                </div>

                                <div className="row mb-2 padding_top">
                                <div className="col-sm-4">
                                    <label htmlFor="customer">Telephone number</label>
                                    <input type="text" name="phone_number" value={phone_number} disabled className="form-control millman-input"  placeholder="Telephone number"/>
                                    </div>
                                <div className="col-sm-4">
                                    <label htmlFor="customer">Gender</label>
                                    <select type="text" name="gender" value={gender} disabled className="form-control millman-input" placeholder="Select gender">
                                        <option>Select gender</option>
                                        <option>Male</option>
                                        <option>Female</option>
                                        <option>Other</option>
                                    </select>
                                    </div>

                                    <div className="col-sm-4">
                                    <label htmlFor="customer">Date of Birth</label>
                                    <input type="date" name="dob" value={dob} disabled className="form-control millman-input"  placeholder="Date of birth"/>
                                    </div> 
                                    </div>                  
                                </div>
                            </div>

                        </div>
                    </div>
                  </div>
                </div> 
                <Footer/>
            </div>    
            

        </Fragment>
    )
}

export default ProfileContainer