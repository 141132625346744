import React, {useState} from "react";
import RightPanel from "../RightPanel";
import Logo from "../Logo";
import {Link} from "react-router-dom";
import GoogleButton from "../../components/Login/GoogleButton";
import PropTypes from "prop-types";

const LoginForm =(props)=>{
    const{ handleSubmit, handleChange }=props
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return(
        <div className="login-reg">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 authfy-panel-left">
                        <Logo />
                        <div className="authfy-login">
                            <div className="authfy-panel panel-login text-center active">
                                <div className="authfy-heading">
                                    <h3 className="auth-title">Login to your account</h3>
                                    <p>Don’t have an account? <Link to="/register" className="lnk-toggler"  data-panel=".panel-signup" > Sign up
                                        for one!</Link></p>
                                </div>
                                <div className="row social-buttons">

                                    <div className="col-sm-12">
                                        <GoogleButton />
                                    </div>
                                </div>
                                <div className="row loginOr">
                                    <div className="col-sm-12">
                                        <span className="spanOr">or</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <form name="loginForm" className="loginForm" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input type="email" className="form-control email" name="email"
                                                       placeholder="Email address" onChange={handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <div className="pwdMask">
                                                    <input type={passwordShown ? "text" : "password"} onChange={handleChange} className="form-control password" name="password"
                                                           placeholder="Password"/>
                                                    <span className="fa fa-eye-slash pwd-toggle" onClick={togglePassword}></span>
                                                </div>
                                            </div>
                                            <div className="row remember-row">
                                                <div className="col-xs-6 col-sm-6">
                                                    <label className="checkbox text-left">
                                                        <input type="checkbox" value="remember-me"/><span className="label-text">Remember me</span>
                                                    </label>
                                                </div>
                                                <div className="col-xs-6 col-sm-6">
                                                    <p className="forgotPwd">
                                                        <a className="lnk-toggler" data-panel=".panel-forgot" href="#">Forgot
                                                            password?</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-lg btn-primary btn-block" type="submit">Login with email
                                                </button>
                                            </div>
                                            <div className="form-group">
                                                <a className="lnk-toggler" type="submit" href="/register/institution">Education Institution ? Signup for an account!
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RightPanel/>
                </div>
            </div>
        </div>

    );

};
LoginForm.propTypes={
    handleSubmit: PropTypes.func.isRequired
}
export default LoginForm;
