import { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select'
import { customStyle } from "../../config/constants"


class Classes extends Component{
    constructor(props){
        super(props)
        this.state={
            classes:[],
            selectedClass:''
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({classes:nextProps.classes})
    }
    handleSelectChange =selectedClass=>{
        this.setState({selectedClass:selectedClass.value})
        localStorage.setItem('school_class_id', selectedClass.value)

    }

    render(){
        return(
            <Select 
            options={this.state.classes}
            styles={customStyle}
            onChange={this.handleSelectChange}
             />
        )
    }
}

const mapStateToProps = state =>({...state.schools})
const SchoolClasses = connect(mapStateToProps,{})(Classes)
export default SchoolClasses