import jwtDecode from 'jwt-decode'
import {loginSuccessful, registrationSuccessful} from "./actionCreators";
import axiosInstance from "../config/axiosInstance";
import {loginURL, register_learner, addInstitution} from "../config/constants";
import {toast} from "react-toastify";
import {requestLoading} from "./requestLoading";


export const loginUser=userData=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.post(loginURL, userData).then((response)=>{
        const payload = jwtDecode(response.data.auth_token)
        localStorage.setItem('auth_token', response.data.auth_token)
        const userDetails = {
            userType:payload.user_type,
            currentUser: payload.current_user
        }
        localStorage.setItem('user_type', payload.user_type)
        localStorage.setItem('currentUser',payload.current_user)
        dispatch(requestLoading(false))
        dispatch(loginSuccessful(userDetails))
    }).catch(error=>{
        dispatch(requestLoading(false))
        toast.error(error.response.data.message)
        

    })
}

export const registerUser= data=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.post(register_learner, data).then(response=>{
        dispatch(registrationSuccessful())
        dispatch(requestLoading(false))
        toast.success('Registration successful')
    }).catch(error=>{
        dispatch(requestLoading(false))
        toast.error(error.response.data.message)
    })
}

export const registerSchool= data=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.post(addInstitution, data).then(response=>{
        dispatch(registrationSuccessful())
        dispatch(requestLoading(false))
        toast.success(response.data.message)
    }).catch(error=>{
        dispatch(requestLoading(false))
        toast.error(error.response.data.message)
    })
}
