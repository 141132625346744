import {FETCH_FAMILY_DETAILS,ADD_FAMILY_MEMEMBER_SUCCESSFUL} from "../actions/types"
const initialState={
familyMembersDetails:[],
memberAdded:false

}
const familyReducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_FAMILY_DETAILS:
            
            return{
                ...state,
                familyMembersDetails: action.familyPayload
            }
        case ADD_FAMILY_MEMEMBER_SUCCESSFUL:
            return{
                ...state,
                memberAdded:action.payload
            }
        default:
            return state

    }

}
export default familyReducer