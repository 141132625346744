import { GET_LEARNER_APPLICATIONS_SUCCESSFUL } from "../actions/types"

const initialState ={
    applications:[[]]
}

const applicationsReducer = (state=initialState, action)=>{
    switch(action.type){
        case GET_LEARNER_APPLICATIONS_SUCCESSFUL:
            return{
                ...state,
                applications:action.applicationsPayload
            }
        default:
            return state
        
    }

}
export default applicationsReducer
