import {toast} from "react-toastify";

const customToast={
    success(msg, options={}){
        return toast.success(msg, {
            ...options,
            className: 'toast-success-container toast-success-container-after schToast',
            progressClassName:'progressHeight'
        })

    },
    error(msg, options={}){
        return toast.error(msg, {
            ...options,
            className: 'schToast',
            progressClassName:'progressHeight'
        })

    },
    info(msg, options={}){
        return toast.info(msg, {
            ...options,
            className: 'toast-success-container toast-success-container-after schToast',
            progressClassName:'progressHeight'
        })

    }
}
export default customToast