import { Component } from "react";
import { connect } from "react-redux";

class ApplicationFee extends Component{
    constructor(props){
        super(props)
        this.state={
            admission_fee: null
        }
    }

    componentDidMount(){
        const {admission_fee} = this.props
        this.setState({
            admission_fee
        })
    }

    componentWillReceiveProps(nextProps){
        this.setState({admission_fee:nextProps.admission_fee})
    }

    render(){
        return(
            <div><input type="checkbox" name="consent"  required/> &nbsp;
            <label htmlFor="customer"> I agree to be charged UGX {this.state.admission_fee} as application fees</label>
</div>
        )
    }
}

const mapStateToProps =state=>({
    ...state.schools
})

const Consent = connect(mapStateToProps,{})(ApplicationFee)

export default Consent