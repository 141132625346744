import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const  MediaUpload=(props)=> {
  const {handleMediaChange, fileTypes, mediaLabel}=props
  return (
    <FileUploader label={mediaLabel} handleChange={handleMediaChange} name="file" types={fileTypes} classes="mediaUploadField" />
  );
}

export default MediaUpload;