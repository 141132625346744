import React, {Component} from 'react'
import AdminDashboardCont from "../../containers/Dashboard/adminDashboard"

class AdminDashboard extends Component{

    render() {
        return(
            <AdminDashboardCont/>
        )
    }
}

export default AdminDashboard
