import { Component } from "react";
import MyApplicationsContainer from "../../containers/MyApplicationsContainer";
import { defaultMyapplicationColumns } from "../../config/constants";
import { getLearnerApplications } from "../../actions/applicationActions";
import { connect } from "react-redux";

class MyApplications extends Component{
    constructor(props){
        super(props)
        this.state={
            applications:[[]]
        }
    }

    componentDidMount(){
        const {getLearnerApplications} = this.props
        getLearnerApplications()
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            applications:nextProps.applications
        })
    }

    render(){
        const {applications} = this.state
        return(
            <MyApplicationsContainer
            defaultMyapplicationColumns={defaultMyapplicationColumns}
            rows={applications}
            />
        )
    }
}
const mapStateToProps =state=>({
    ...state.learnerApplication
})
const MyApplication = connect(mapStateToProps, {getLearnerApplications})(MyApplications)
export default MyApplication