import { Fragment } from "react"
import { Link } from "react-router-dom"
import Table from "../Table"
import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";

const LeanerAdmissionsContainer = (props) => {
    const { defaultMyapplicationColumns, rows } = props;

    return (
        <Fragment>
            <div className='wrapper sidebar-mini layout-fixed'>
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            {rows.length === 0 ? (
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0">Applications</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                            <li className="breadcrumb-item active">Applications</li>
                                        </ol>
                                    </div>
                                </div>
                            ) : null} {/* Render nothing if there are no applications */}

                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    {rows.length === 0 ? ( // Check for no applications
                                        <div className="empty-panel">
                                            <div className="application-list-empty">
                                            <h5>No applications found</h5>

                                                It seems you haven't submitted any applications yet. Please explore the available schools in the categories listed in the menu on the left to get started.
                                            </div>
                                        </div>
                                    ) : (
                                        <Table cols={defaultMyapplicationColumns} dbRows={rows} tableType="advanced" />
                                    )}
                                </div>
                            </div>        
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Fragment>
    );
}

export default LeanerAdmissionsContainer
