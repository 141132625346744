import { Component } from "react";
import { connect } from "react-redux";
import ProfileContainer from "../../containers/Profile";
import {getBasicInfo} from "../../actions/profileActions"

class Profile extends Component{

    constructor(props){
        super(props)
        this.state={
            currentUser:' ',
            first_name:' ',
            last_name:' ',
            email:' ',
            dob:' ', 
            phone_number:' ',
            gender:' '
        }
    }


    static getDerivedStateFromProps(nextProps){
        const {first_name, last_name, email, dob, phone_number, gender,currentUser}=nextProps

            return { first_name, last_name, email, dob, phone_number, gender,currentUser }
 
    }
    

    render(){
        const { first_name, last_name, email, dob, phone_number, gender, currentUser} = this.state
        return(
            <ProfileContainer
                currentUser={currentUser}
                first_name={first_name}
                last_name={last_name}
                email={email}
                dob={dob}
                phone_number={phone_number}
                gender={gender}
            />
        )
    }
}

const mapStateToProps = state =>({
    ...state.user
})
const UserProfile = connect(mapStateToProps, {getBasicInfo})(Profile)
export default UserProfile
