import { MEDICALL_DETALS_FETCH_SUCCESSFUL } from "../actions/types"


const initialState ={
    learnerMedicalDetails:[]
}

const  medicalReducer=(state=initialState, action)=>{
    switch(action.type){
        case MEDICALL_DETALS_FETCH_SUCCESSFUL:
            return{
                ...state,
                learnerMedicalDetails:action.medicaDetails
            }
        default:
            return state

    }
}

export default medicalReducer