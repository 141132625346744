import { Fragment } from "react"
import { Link } from "react-router-dom"
import Header from "../Header"
import Footer from "../Footer"
import Sidebar from "../Sidebar"
import ProfileNav from "./ProfileNav"
import Table from "../Table"
import { defaultEmergencyColumns } from "../../config/constants"
import EmergencyContactsModal from "./emergencyContactsModal"

const MedicalContainer =(props)=>{
    const {dbRows, sick, handleChangeOnModal, handleSubmit} = props
    console.log('DB ROWS', dbRows)

    return(
        <Fragment>
            <div className='wrapper  sidebar-mini layout-fixed'>
                <Header/>
                <Sidebar/>
                <div className="content-wrapper">
                 <div className="content-header">
                    <div className="container-fluid">
                      <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Profile</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">profile</li>
                                </ol>
                            </div>
                        </div>
                        <div className="row mb-2">

                        <ProfileNav />
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-12 card">
                                <div className="card-header">
                                    <div className="row mb-2">
                                        <div className="col-sm-6 profile-panel-padding">
                                        <h5>Learner's Emergency Contacts</h5>
                                        </div>
                                        <div className="col-sm-6"><button className="btn btn-success float-right" data-toggle="modal" data-target="#medicalModal">Add emergecny contacts</button></div>
                                    </div>
                                </div>
                                <div className="card-body padding-bottom">
                                <EmergencyContactsModal sick={sick} handleSubmit={handleSubmit} handleChangeOnModal={handleChangeOnModal}/> 

                                <div className="row mb-2 padding_top">
                                {
                                                dbRows.length===0?( 
                                                    <div className="empty-panel">
                                                        <h5>Add emergency contacts</h5>
                                                         <div className="school-list-empty"> Our professional school management system delivers effective application, admission, reporting and insight across education institutions. Click add emergency contacts to update profile ...</div>

                                                    </div>
                                                ):(
                                                <Table cols={defaultEmergencyColumns}
                                                    dbRows={dbRows}
                                                    handleSubmit={handleSubmit}
                                                    tableType="advanced"/>
                                                )
                                            } 

                                 </div>                  
                                </div>
                            </div>

                        </div>
                    </div>
                  </div>
                </div> 
                <Footer/>
            </div>    
        </Fragment>
    )
}
export default MedicalContainer
