import { Component } from "react";
import { connect } from "react-redux";
import {getFamilyInfo, addFamilyMember} from "../../actions/profileActions"
import FamilyModal from "../../containers/Profile/FamilyModal";


class NewFamilyMember extends Component{
    constructor(props){
        super(props)
        this.state={
            first_name:' ', 
            last_name:' ', 
            email:' ', 
            telephone_number:' ',
            relationship:' ', 
            occupation:' ', 
            cell_phone_number:' ', 
            address:' ',
            corespondent:'No', 
            currentUser:''
        }
    }


    handleSubmit = event =>{
        const {first_name, last_name, email, telephone_number,
            relationship, occupation, cell_phone_number, address,
              corespondent}=this.state

        event.preventDefault()
        const {addFamilyMember}=this.props
        const familyMember = {
            family:{
                first_name, last_name, email, telephone_number,
                relationship, occupation, cell_phone_number, address,
              corespondent
            }
        }
        addFamilyMember(familyMember)
    }
    handleChange=event=>{
        const {name,value}=event.target
        this.setState({[name]:value})
    }

    render(){
        return(
            <FamilyModal
            handleSubmitOnModal={this.handleSubmit}
            handleChangeOnModal={this.handleChange}
            
            />
        )
    }
}

const mapStateToProps = state =>({
    ...state.family
})
const NewFamilyProfile = connect(mapStateToProps, {getFamilyInfo, addFamilyMember})(NewFamilyMember)
export default NewFamilyProfile