import {Fragment} from "react";
import { BrowserRouter } from 'react-router-dom';
import Routes from "./components/Routes";
import './static/css/sch-admin.css';
import {Provider} from "react-redux";
import store from "./store";
import {ToastContainer, Slide} from 'react-toastify'
import  'react-toastify/dist/ReactToastify.css'
import PageLoader from "./components/PageLoader";


function App() {
  return (
      <Provider store={store}>
          <BrowserRouter forceRefresh={true}>
            <Fragment>
                <PageLoader/>
                <ToastContainer
                    position={"top-right"}
                    transition={Slide}
                    autoClose= {10000}
                    closeButton={true}
                    theme={"light"}
                    className="schToast"
                    progressClassName="progressHeight"
                    borderRadius= "15px" />
              <Routes />
            </Fragment>
          </BrowserRouter>
      </Provider>
  );
}

export default App;
