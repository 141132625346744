import React, { Component } from "react";
import { connect } from "react-redux";
import InstitutionForm from "../../containers/InstitutionForm";
import {registerInstitution} from "../../actions/institutionActions"

class Institution extends Component{
    constructor(props){
        super(props)
        this.state={
            name:'',
            school_type:'',
            email:'',
            password:'',
            phone_number:'',
            admission_fee:'',
            mediaFile:{},
            schools:[]
        }
    }

    onChange = event=>{
        const {name,value}=event.target
        this.setState({[name]:value})
    }

    handleMediaChange = (file) => {
        this.setState({mediaFile:file})

  }
  handleClear=event=>{
      
  }
  

    handleSubmit=event=>{
        event.preventDefault()
        const {registerInstitution}=this.props
        const institutionData = this.state
        const mediaFile =this.state.mediaFile
        registerInstitution(institutionData, mediaFile)
    }


    render(){
        return(
            <InstitutionForm
             handleSubmit={this.handleSubmit}
             onChange={this.onChange}
             handleMediaChange={this.handleMediaChange}
             dbRows={[]}
             />
        )
    }

}


export default connect(null,{registerInstitution}) (Institution)
