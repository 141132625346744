import React from "react";

const RightPanel=()=>(

    <div className="col-md-7 col-lg-8 authfy-panel-right hidden-xs hidden-sm">
        <div className="hero-heading row">
            <div id="authfySlider" className="headline carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#authfySlider" data-slide-to="0" className="slider-control active"></li>
                    <li data-target="#authfySlider" data-slide-to="1" className="slider=control"></li>
                    <li data-target="#authfySlider" data-slide-to="2" className="slider-control"></li>

                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item">
                        <h3>Welcome to School Admissions</h3>
                        <p>Now, schools do not need to maintain bulky registers to record the details of newly admitted students. Instead,<br></br>
                        we automate the enrolment procedure with flexibility, cost optimization, and the possibility to access it through multiple platforms.</p>
                    </div>
                    <div className="carousel-item active">
                        <h3>Apply For Education Studies anywhere</h3>
                        <p>The official website for application to nursery, primary, secondary and higher education studies anywhere.</p>
                    </div>

                    <div className="carousel-item">
                        <h3>School Admissions</h3>
                        <p>A successful admissions process means meeting deadlines. Find out all the important dates for your application.

</p>
                    </div>
                </div>
            </div>
        </div>

    </div>

)
export default RightPanel;
