const TableHeader=({cols})=>(
    <thead className="">
        <tr>
            <th><input type="checkbox" className="sch_check" name="select_all"/></th>
            {cols.map(col=><th key={col}>{col}</th>)}
        </tr>    
    </thead>

)

export default TableHeader