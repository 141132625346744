import {REQUEST_LOADING} from "../actions/types";

const initialState={
    loading:false
}

const loadingReducer =(state=initialState, action)=>{
    switch(action.type){
        case REQUEST_LOADING:
            return{
                ...state,
                loading: action.payload
            }
        default:
            return state

    }
}
export default loadingReducer