import React, {useState} from 'react'
import Logo from "../Logo";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import RightPanel from "../RightPanel";

const SchoolRegistrationForm =(props)=> {
    const {handleSubmit, handleChange}=props;
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    return(
        <div className="login-reg">
            {/*<Preloader/>*/}
            <div className="container-fluid">
                  <div className="row">
                <div className="col-xs-12 col-sm-7 col-md-5 col-lg-4 authfy-panel-left">
                    <Logo/>
                    <div className="authfy-login">
                        <div className="authfy-panel panel-login text-center active">
                            <div className="authfy-heading">
                                <h3 className="auth-title">Sign up for an Institution account</h3>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12">

                                    <form name="signupForm" className="signupForm" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="name"
                                                   placeholder="Institution name" onChange={handleChange}/>
                                        </div>
                                        <div className="form-group">
                                                    <select type="text" name="school_type" className="form-control millman-input" id="customer"
                                                        placeholder="Institution Type" onChange={handleChange}>
                                                            <option>Select Institution Type</option>
                                                            <option value="elementary">Elementary</option>
                                                            <option  value="primary">Primary</option>
                                                            <option value="secondary">Secondary</option>
                                                            <option value="tertiary">Tertiary</option>
                                                            <option value="university">University</option>
                                                    </select>
                                        </div>

                                        <div className="form-group">
                                            <input type="email" className="form-control" name="email" onChange={handleChange}
                                                   placeholder="Email address"/>
                                        </div>

                                        <div className="form-group">
                                                <div className="pwdMask">
                                                    <input type={passwordShown ? "text" : "password"} onChange={handleChange} className="form-control password" name="password"
                                                           placeholder="Password"/>
                                                    <span className="fa fa-eye-slash pwd-toggle" onClick={togglePassword}></span>
                                                </div>
                                        </div>
                                        <div className="form-group">
                                            <p className="term-policy text-muted small">I agree to the <Link to="">privacy
                                                policy</Link> and <Link to="">terms of service</Link>.</p>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-lg btn-primary btn-block" type="submit">Sign up
                                                with email
                                            </button>
                                        </div>
                                    </form>
                                    <Link className="lnk-toggler" data-panel=".panel-login" to="/">Already have an
                                        account?</Link>

                                </div>
                            </div>
                        </div>
                        <div className="authfy-panel panel-signup text-center">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <div className="authfy-heading">
                                        <h3 className="auth-title">Sign up for free!</h3>
                                    </div>
                                    <form name="signupForm" className="signupForm" action="#" method="POST">
                                        <div className="form-group">
                                            <input type="email" className="form-control" name="email"
                                                   placeholder="Email address"/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="fullname" placeholder="Full name"/>
                                        </div>
                                        <div className="form-group">
                                            <div className="pwdMask">
                                                <input type="password" className="form-control" name="password"
                                                       placeholder="Password"/>
                                                <span className="fa fa-eye-slash pwd-toggle"></span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p className="term-policy text-muted small">I agree to the <Link to="/">privacy
                                                policy</Link> and <Link to="/">terms of service</Link>.</p>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-lg btn-primary btn-block" type="submit">Sign up with email
                                            </button>
                                        </div>
                                    </form>
                                    <Link className="lnk-toggler" data-panel=".panel-login" to="#">Already have an account?</Link>
                                </div>
                            </div>
                        </div>

                        <div className="authfy-panel panel-forgot">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <div className="authfy-heading">
                                        <h3 className="auth-title">Recover your password</h3>
                                        <p>Fill in your e-mail address below and we will send you an email with further
                                            instructions.</p>
                                    </div>
                                    <form name="forgetForm" className="forgetForm" action="#" method="POST">
                                        <div className="form-group">
                                            <input type="email" className="form-control" name="username"
                                                   placeholder="Email address"/>
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-lg btn-primary btn-block" type="submit">Recover your
                                                password
                                            </button>
                                        </div>
                                        <div className="form-group">
                                            <a className="lnk-toggler" data-panel=".panel-login" href="#">Already have an
                                                account?</a>
                                        </div>
                                        <div className="form-group">
                                            <a className="lnk-toggler" data-panel=".panel-signup" href="#">Don’t have an
                                                account?</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RightPanel/>
            </div>
            </div>
        </div>
    )
}
SchoolRegistrationForm.propTypes={
    handleSubmit: PropTypes.func.isRequired
}

export default SchoolRegistrationForm