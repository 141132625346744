import {REGISTER_INSTITUTION_INITIATED} from "../actions/types"
const initialState={
    institutions:[],
    classes:[[]],
    loading:false
}

const institutionReducer=(state=initialState, action)=>{
    switch(action.type){
        case REGISTER_INSTITUTION_INITIATED:
            return{
                ...state,
                loading:true
            }
        default:
            return state
    }

}
export default institutionReducer