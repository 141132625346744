import { Component } from "react";
import SchoolApplicationsContainer from "../../containers/SchoolApplicationsContainer";
import { defaultSchoolapplicationsColumns } from "../../config/constants";
import { getSchoolApplications } from "../../actions/applicationActions";
import { connect } from "react-redux";

class SchoolApplications extends Component{
    constructor(props){
        super(props)
        this.state={
            applications:[[]]
        }
    }

    componentDidMount(){
        const {getSchoolApplications} = this.props
        getSchoolApplications()
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            applications:nextProps.applications
        })
    }

    render(){
        const {applications} = this.state
        return(
            <SchoolApplicationsContainer
            defaultMyapplicationColumns={defaultSchoolapplicationsColumns}
            rows={applications}
            />
        )
    }
}
const mapStateToProps =state=>({
    ...state.learnerApplication
})
const MyApplication = connect(mapStateToProps, {getSchoolApplications})(SchoolApplications)
export default MyApplication