import { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select'
import {getSchools, getClasses} from "../../actions/institutionActions"
import { getAdmissionFee } from "../../actions/applicationActions";
import { customStyle } from "../../config/constants";

class Schools extends Component{
    constructor(props){
        super(props)
        this.state={
            schools:[],
            selectedSchool: null,
            admission_fee:null

        }
    }

    componentWillMount(){
        const {getSchools}=this.props
        const page_location = window.location.pathname
        const school_type = page_location.split('/')[2]
        getSchools(school_type)
        const {schools, admission_fee}=this.props
        this.setState({
            schools,
            admission_fee
        })
 
    }

    componentWillReceiveProps(nextProps){
        const page_location = window.location.pathname
        const school_type = page_location.split('/')[2]
        this.setState({
            schools: nextProps.schools,
            admission_fee:nextProps.admission_fee
        })

    }

    handleSelectChange=(select)=>{
        this.setState({
            selectedSchool:select.value
        })
        localStorage.setItem('school_id', select.value)
        const {getAdmissionFee} = this.props
        const {getClasses} = this.props
        getAdmissionFee(select.value)   
        getClasses(select.value)
    }


    render(){
        return(
            <Select 
            options={this.state.schools}
            styles={customStyle}
            onChange={this.handleSelectChange}
             />
        )
    }
}

const mapStateToProps= state =>({ ...state.schools})

const SchoolsList = connect(mapStateToProps,{getSchools,getClasses,getAdmissionFee})(Schools)

export default SchoolsList
