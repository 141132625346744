import { useState } from 'react';
import MediaModal from './mediaModal';
import axiosInstance from "../../config/axiosInstance";
import { admissionsURL } from '../../config/constants';
import {toast} from "react-toastify";


const TableBody = ({ dbRows }) => {
    const [modalState, setModalState] = useState({
        isOpen: false,
        mediaUrl: '',
        title: '',
        selectedMedia: null,
        applicationId: null
    });

    const openModal = (url, media, title) => {
        setModalState({
            isOpen: true,
            mediaUrl: url,
            title,
            selectedMedia: media,
            applicationId: media[0] // Assuming the first element is the applicationId
        });
    };

    const closeModal = () => {
        setModalState({
            isOpen: false,
            mediaUrl: '',
            title: '',
            selectedMedia: null,
            applicationId: null
        });
    };

    const saveMedia = async () => {
        const admissionsData = {
            status: 'accepted',
            application_id: modalState.applicationId
        };

        try {
            const response = await axiosInstance.post(admissionsURL, admissionsData);
            console.log('Response:', response);
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response.data.message) 
            console.error('Error saving media:', error.message);
        } finally {
            closeModal();
        }
    };

    const deleteMedia = async () => {
        const admissionsData = {
            status: 'declined',
            application_id: modalState.applicationId
        };

        try {
            const response = await axiosInstance.post(admissionsURL, admissionsData);
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response.data.message) 
        } finally {
            closeModal();
        }
    };

    return (
        <>
            <tbody>
                {dbRows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        <td>
                            <input
                                type="checkbox"
                                value={row[0]}
                                className="sch_check"
                                name="slxt_all_check"
                            />
                        </td>
                        {row.filter(cell => typeof cell !== 'number').map((cellData, cellIndex) => {
                            const isLink = typeof cellData === 'string' && (cellData.startsWith('http') || cellData.startsWith('https'));
                            return (
                                <td key={cellIndex}>
                                    {isLink ? (
                                        <button
                                            onClick={() => openModal(cellData, row, row[2])} // Pass the third item as title
                                            className="btn btn-link"
                                        >
                                            View
                                        </button>
                                    ) : (
                                        cellData // Just render cellData directly
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>

            {/* Modal component - rendered outside of tbody */}
            {modalState.isOpen && (
                <MediaModal
                    mediaUrl={modalState.mediaUrl}
                    title={modalState.title}
                    onClose={closeModal}
                    onSave={saveMedia}
                    onDelete={deleteMedia}
                    applicationId={modalState.applicationId}
                />
            )}
        </>
    );
};

export default TableBody;
