import {Component} from "react";
import {Authenticate} from "../PrivateRoute";
import {Redirect, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class Logout extends Component{
    componentWillMount() {
        const {dispatch} = this.props
        Authenticate.logout(dispatch)
    }

    render() {
        return (
            <Redirect to="/" />
        );
    }
}

Logout.propTypes = {
    dispatch: PropTypes.func.isRequired
}
export default withRouter(connect()(Logout))