import MedialUpload from "../MediaUpload"


const InstitutionModal=(props)=>{
    const {onChange,handleSubmitOnModal, handleMediaChange}=props
    const fileTypes = ["JPG", "PNG", "JPEG"];
    const mediaLabel = "Upload Institution Logo"
    return(
                    <form onSubmit={handleSubmitOnModal}>

<div className="modal fade" id="instituionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="basicProfileLabel">Institution Details</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div className="modal-body">
          <div className="row mb-2">
              <div className="col-sm-6">
              <div className="form-group main-form main-form-first" >
                                                <label htmlFor="customer">Institution Type</label>
                                                    <select type="text" name="school_type" className="form-control millman-input" id="customer"
                                                        placeholder="Institution Type" onChange={onChange}>
                                                            <option>Select Institution</option>
                                                            <option value="elementary">Elementary</option>
                                                            <option  value="primary">Primary</option>
                                                            <option value="secondary">Secondary</option>
                                                            <option value="tertiary">Tertiary</option>
                                                            <option value="university">University</option>
                                                    </select>
                                                </div>
              </div>
              <div className="col-sm-6">
              <div className="form-group main-form main-form-first" >
                                                <label htmlFor="customer">Institution Name</label>
                                                    <input type="text" name="name" className="form-control millman-input" id="customer"
                                                        placeholder="Institution name" onChange={onChange}/>
                                                </div>
              </div>
          </div>
          <div className="row mb-2">
              <div className="col-sm-6">
              <div className="form-group main-form main-form-first" >
                                                <label htmlFor="customer">Phone number</label>
                                                    <input type="text" name="phone_number" className="form-control millman-input" id="customer"
                                                        placeholder="Phone number" onChange={onChange}/>
                                                </div>
              </div>
              <div className="col-sm-6">

              <div className="form-group main-form main-form-first" >
                                                <label htmlFor="customer">Institution Email</label>
                                                    <input type="text" name="email" className="form-control millman-input" id="customer"
                                                        placeholder="Institution Email" onChange={onChange}/>
                                                </div>
              </div>
          </div>
          <div className="row mb-2">
              <div className="col-sm-6">
              <div className="form-group main-form main-form-first" >
                                                <label htmlFor="customer">Login Password</label>
                                                    <input type="text" name="password" className="form-control millman-input" id="customer"
                                                        placeholder="Login password" onChange={onChange}/>
                                                </div>

              </div>
              <div className="col-sm-6">
              <div className="form-group main-form main-form-first" >
                                                <label htmlFor="customer">Admission Fees</label>
                                                    <input type="text" name="admission_fee" className="form-control millman-input" id="customer"
                                                        placeholder="Admission Fees" onChange={onChange}/>
                                                </div>
              </div>

              <div className="col-sm-12">
              <label htmlFor="customer">Institution logo</label>
                  <MedialUpload fileTypes={fileTypes} mediaLabel={mediaLabel} handleMediaChange={handleMediaChange} />

              </div>
          </div>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Close Panel</button>
        <button type="submit" className="btn btn-success">Save changes</button>
      </div>
    </div>
  </div></div>
        

    </form>
    )
}

export default InstitutionModal

