import { Fragment } from "react"
import { Link } from "react-router-dom"
import Footer from "../Footer"
import Header from "../Header"
import AdminSideBar from "../Sidebar/adminSideBar"
import MedialUpload from "../MediaUpload"
import { defaultInstituionColumns } from "../../config/constants"
import Table from "../Table"
import InstitutionModal from "./instituionModal"

const InstitutionForm=(props)=>{
    const{onChange, handleSubmit, handleMediaChange, dbRows, handleChangeOnModal}=props
    // const fileTypes = ["JPG", "PNG", "JPEG"];
    // const mediaLabel = "Upload Institution Logo"

    return(
        <Fragment>
        <div className='wrapper  sidebar-mini layout-fixed'>
            <Header/>
            <AdminSideBar/>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Institutions</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Institutions</li>
                                </ol>
                            </div>
                        </div>

                            <div className="col-sm-12 card">
                                <div className="card-header">
                                    <div className="row mb-2">
                                        <div className="col-sm-6 profile-panel-padding">
                                        <h5>Institution Information</h5>
                                        </div>
                                        <div className="col-sm-6"><button type="button" className="btn btn-success float-right" data-toggle="modal" data-target="#instituionModal">Add Institution</button></div>
                                    </div>
                                </div>

                                <div className="card-body padding-bottom">
                                    <div className="row mb-2">
                                        <div className="col-sm-12">
                                            {
                                                dbRows.length==0?( 
                                                    <div className="empty-panel">
                                                        <h5>Add institution</h5>
                                                         <div className="school-list-empty"> Our professional school management system delivers effective communication, control, reporting and insight across education institutions. Click add institution to start creating  ...</div>
                                                    </div>
                                                ):(<Table cols={defaultInstituionColumns} dbRows={dbRows} tableType="advanced"/>)
                                            }
                                        
                                        </div>
                                    </div>
                                </div>
                                <InstitutionModal
                                    handleChangeOnModal={handleChangeOnModal}
                                    handleSubmitOnModal={handleSubmit}
                                    handleMediaChange={handleMediaChange}
                                    onChange={onChange}
                                />

                            </div>
            
    </div>
</div>
</div>    
<Footer/>
</div>

            

        </Fragment>
    )
}
export default InstitutionForm