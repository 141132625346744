import {Fragment} from "react";
import { Link } from "react-router-dom";
import SchoolSidebar from "../Sidebar/schoolSideBar";

import Footer from "../Footer";
import Header from "../../components/Header"
import Table from "../Table";
import { defaultClassColumns } from "../../config/constants";

const ClassCourseForm =(props)=>{
    const { onChange,handleSubmit, classes }=props

    return(
        <Fragment>
        <div className='wrapper  sidebar-mini layout-fixed'>
            <Header/>
            <SchoolSidebar/>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Programs</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Add programs</li>
                                </ol>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <div className="form-group main-form main-form-first" >
                                <label htmlFor="customer">program name</label>
                                    <input type="text" name="name" required className="form-control millman-input" id="customer"
                                        placeholder="Enter Class / Course name" onChange={onChange}/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="form-group main-form main-form-first" >
                            <label htmlFor="customer">&nbsp;</label>
                                <button type="submit" className="btn btn-block btn-success"><i className="fa fa-save"></i> Add class or course</button>
                             </div>
                            </div>
                        </div>
                        </form>

                        <div className="row mb-2">
                            <div className="col-sm-12">
                            {
                                classes?.length===0?( 
                                        <div className="empty-panel">
                                            <h5>No classes or courses available</h5>
                                                <div className="school-list-empty"> It seems there are no classes or courses to show at the moment. Please click 'Add Class/Course' to get started and create your own!</div>

                                        </div>
                                    ):(<Table cols={defaultClassColumns} dbRows={classes} tableType="advanced"/>)
                                }
                        
                            </div>
                        </div>        
                        
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    </Fragment>
    )

}
export default ClassCourseForm
