const EmergencyContactsModal = (props)=>{
    const {  sick, handleSubmit, handleChangeOnModal} = props
    return(
        <form onSubmit={handleSubmit}>

<div className="modal fade" id="medicalModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="basicProfileLabel">Medical Details</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
          <div className="row mb-2">
              <div className="col-sm-12">
              <label htmlFor="customer">Do you have any illness?</label>
        <select  name="sick" onChange={handleChangeOnModal} className="form-control millman-input" placeholder="Do you have any illness">
              <option value="false" selected disabled>&nbsp;</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
        </select>
              </div>
          </div>
          { 

          sick==="true"?(   <div className="medical-display">
          <div className="row mb-2">
              <div className="col-sm-6">
              <label htmlFor="customer">Type of sickness</label>
          <input type="text" name="sickness_type" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Sickness type"/>
        
              </div>
              <div className="col-sm-6">
              <label htmlFor="customer">Physician name</label>
          <input type="text" name="learners_physcian" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Physician's name"/>
        
              </div>


          </div>
          <div className="row mb-2">
              <div className="col-sm-6">
              <label htmlFor="address">Physician address</label>
                <input type="text" name="physcian_address" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Physician address"/>
        
              </div>
              <div className="col-sm-6">
              <label htmlFor="telephone">Physician Telephone number</label>
                 <input type="text" name="physcian_tel" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Telephone number"/>
        

              </div>


          </div>
          <div className="row mb-2">
              <div className="col-sm-12">
              <label htmlFor="allergies">Allergies</label>

                  <textarea className="form-control" onChange={handleChangeOnModal} name="allergies" placeholder="Leave this field empty if you do not have allergies"></textarea>

              </div>

          </div>
          <div className="row mb-2">
              <div className="col-sm-12">
              <label htmlFor="postal_code">Physician Postal address</label>

                  <textarea className="form-control" onChange={handleChangeOnModal} name="postal_code"></textarea>

              </div>

          </div>

          </div>):('')

          }



      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Close Panel</button>
        <button type="submit" className="btn btn-success">Save changes</button>
      </div>
    </div>
  </div></div>
        

    </form>
    )
}
export default EmergencyContactsModal