import {Fragment} from "react";
import { Link } from "react-router-dom";
import AdminSidebar from "../../containers/Sidebar/adminSideBar"
import Footer from "../Footer";
import Header from "../../components/Header"

const AdminDashboard =()=>(
    <Fragment>
        <div className='wrapper  sidebar-mini layout-fixed'>
            <Header/>
            <AdminSidebar/>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Admin Dashboard</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Admin Dashboard</li>
                                </ol>
                            </div>
                        </div>
                        <div className="row mb-2">
                        <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Institutions</span>
                                    <span className="info-box-number">2,000</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                <span className="info-box-icon bg-info elevation-1"><i className="fas fa-cog"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Total applications</span>
                                    <span className="info-box-number">
                                    {(10000000).toLocaleString()}
                                    </span>
                                </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                <span className="info-box-icon bg-danger elevation-1"><i className="fa fa-money"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Total Revenue (UGX)</span>
                                    <span className="info-box-number">{(4000000000).toLocaleString()}</span>
                                </div>
                                </div>
                            </div>

                            <div className="clearfix hidden-md-up"></div>

                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                <span className="info-box-icon bg-success elevation-1"><i className="fas fa-shopping-cart"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Sch-admin fees (UGX)</span>
                                    <span className="info-box-number">{(20000000000).toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
                                </div>
                                </div>
                            </div>

                        </div>
                       
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-center text-center">
                                                <h3 className="card-title text-center">Number of admissions by school</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="position-relative mb-4 panel-height text-center empty_panel">
                                                      <i className="fa fa-folder fa-5x"></i>
                                                      <h5><small className="text-muted">You currently have no school with admissions.Encourage students to apply.</small></h5>
                                                </div>   
                                            </div>                                        
                                        </div>
                             </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-center ">
                                                <h3 className="card-title">Number of aplication by school</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex ">
                                            <div className="position-relative mb-4 panel-height text-center empty_panel">
                                                      <i className="fab fa-adn fa-5x"></i>
                                                      <h5><small className="text-muted">You have not made schools in the system. add schools in their categories.</small></h5>
                                                </div>                                    
                                                
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>        
                        
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    </Fragment>
)
export default AdminDashboard
