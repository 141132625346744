import { 
    SCHOOLS_FETCH_SUCCESSFUL,
    SCHOOL_CLASSES_FETCH_SUCCESSFUL,
    GET_APPLICATION_FEE_SUCCESSFUL,
    ADD_CLASS_SUCCESSFUL,
    GET_TOTAL_APPLICATIONS,
    GET_TOTAL_REJECTIONS,
    GET_TOTAL_ADMISSIONS,
    GET_SCHOOL_ADMISSIONS_SUCCESSFUL
} from "../actions/types"

const initialState={
    schools:[],
    classes:[],
    admission_fee:null,
    totalApplications: '0',
    totalRejections: '0',
    totalAdmissions: '0',
    applications:[],
    admissionList:[]
}

const schoolsReducer=(state=initialState, action)=>{

    switch(action.type){
        case SCHOOLS_FETCH_SUCCESSFUL:
            return{
                ...state,
                schools:action.schoolsPayload
            }
        case SCHOOL_CLASSES_FETCH_SUCCESSFUL:
            return{
                ...state,
                classes: action.classPayload
            }
        case ADD_CLASS_SUCCESSFUL: // Handle the new action here
            return {
                ...state,
                classes: [...state.classes, action.classPayload] // Add the new class
            }
        case GET_APPLICATION_FEE_SUCCESSFUL:
            return{
                ...state,
                admission_fee: action.admission_fee
            }
        case GET_TOTAL_APPLICATIONS:
            return{
                ...state,
                totalApplications: action.totalApplications
            }
        case GET_TOTAL_REJECTIONS:
            return{
                ...state,
                totalRejections: action.totalRejections
            }
        case GET_TOTAL_ADMISSIONS:
            return{
                ...state,
                totalAdmissions: action.totalAdmissions
            }
            case GET_SCHOOL_ADMISSIONS_SUCCESSFUL:
                return{
                    ...state,
                    admissionList: action.admissionList
                }
        default:
            return state
    }
}

export default schoolsReducer