const FamilyModal = (props)=>{
    const {  handleSubmitOnModal, handleChangeOnModal} = props
    return(
        <form onSubmit={handleSubmitOnModal}>

<div className="modal fade" id="familyProfile" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="basicProfileLabel">Family member details</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row mb-2">
        <div className="col-sm-6">
        <label htmlFor="customer">First name</label> <i class="fa-regular fa-family"></i>
          <input type="text" name="first_name" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="First name"/>
        </div>

        <div className="col-sm-6">
        <label htmlFor="customer">Last name</label>
        <input type="text" name="last_name" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Last name"/>
        
        </div>

        </div>

        <div className="row mb-2">
        <div className="col-sm-6">
        <label htmlFor="customer">Relationship</label>
        <select type="text" name="relationship" onChange={handleChangeOnModal} className="form-control millman-input" placeholder="Select gender">
              <option>Select your relatioship</option>
              <option>Father</option>
              <option>Mother</option>
              <option>Guardian</option>
        </select>
        </div> 

        <div className="col-sm-6">
        <label htmlFor="customer">Email address</label>
          <input type="email" name="email" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Email address"/>
        </div> 
        </div>
        <div className="row mb-2">

        <div className="col-sm-6">
        <label htmlFor="customer">Telephone Number</label>
          <input type="text" name="telephone_number" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Office Telephone number"/>
        </div>

        <div className="col-sm-6">
        <label htmlFor="customer">Mobile number</label>
        <input type="text" name="cell_phone_number" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Mobile Telephone number"/>
        </div>
        </div>
        <div className="row mb-2">
        <div className="col-sm-6">
        <label htmlFor="customer">Address</label>
          <input type="text" name="address" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Address"/>
        </div>


        <div className="col-sm-6">
        <label htmlFor="customer">Occupation</label>
          <input type="text" name="occupation" onChange={handleChangeOnModal} className="form-control millman-input"  placeholder="Occupation"/>
        </div> 

        </div>
        <div className="row mb-2">
        <div className="col-sm-12">
        <label htmlFor="customer">Correspondent &nbsp;</label><i className="fa fa-info-circle" data-toggle="tooltip"></i>
        <select type="text" name="corespondent" onChange={handleChangeOnModal} className="form-control millman-input" placeholder="Is contect person your correspondent">
              <option>is this person your corespondent</option>

              <option>Yes</option>
              <option>No</option>
        </select>
        </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Close Panel</button>
        <button type="submit" className="btn btn-success">Save changes</button>
      </div>
    </div>
  </div></div>
        

    </form>
    )
}
export default FamilyModal