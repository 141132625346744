import { Component } from "react";
import SchBarChart from "../../containers/Charts/bar";

class BarChart extends Component{

    render(){
        return(
            <SchBarChart/>
        )
    }

}

export default BarChart