import React, {Component} from 'react'
import { connect } from 'react-redux'
import { makeApplication } from '../../actions/applicationActions'
import ApplicationForm from '../../containers/ApplicationForm'

class Application extends Component{
    constructor(props){
        super(props)
        this.state={
            classes:[],
            reportFile:{},
            application_fee:null
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({classes:nextProps.classes, admission_fee:nextProps.admission_fee})
    }
    handleSubmit= event =>{
        event.preventDefault()
        const { makeApplication } =this.props
        const reportFile = this.state.reportFile
        const applicationData = {
        }
        //
        makeApplication(applicationData, reportFile)

    }
    handleMediaChange=reportFile=>{
        this.setState({reportFile})
    }


    render(){
        return(
            <ApplicationForm
            classes={this.state.classes}
            handleSubmit={this.handleSubmit}
            handleMediaChange={this.handleMediaChange}
            admission_fee={this.state.admission_fee}
            />
        )
    }
}
const mapStateToProps = state=>({
    ...state.school
})
const schoolApplication = connect(mapStateToProps,{makeApplication})(Application)
export default schoolApplication