export const LOGIN_INITIATED = 'LOGIN_INITIATED'
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const REQUEST_LOADING = 'REQUEST_LOADING'
export const REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL'
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR'

export const REGISTER_INSTITUTION_INITIATED ='REGISTER_INSTITUTION_INITIATED'
export const REGISTER_INSTITUTION_SUCCESS = 'REGISTER_INSTITUTION_SUCCESS'

export const SCHOOLS_FETCH_SUCCESSFUL = 'SCHOOLS_FETCH_SUCCESSFUL'
export const SCHOOL_CLASSES_FETCH_SUCCESSFUL = 'SCHOOL_CLASSES_FETCH_SUCCESSFUL'
export const GET_APPLICATION_SUCCESSFUL = 'GET_APPLICATION_SUCCESSFUL'
export const GET_APPLICATION_FEE_SUCCESSFUL ='GET_APPLICATION_FEE_SUCCESSFUL'
export const ADD_CLASS_SUCCESSFUL = 'ADD_CLASS_SUCCESSFUL'

export const GET_LEARNER_APPLICATIONS_SUCCESSFUL ='GET_LEARNER_APPLICATIONS_SUCCESSFUL'

export const GET_PROFILE_SUCCESSFUL = 'GET_PROFILE_SUCCESSFUL'
export const ADD_FAMILY_MEMEMBER_SUCCESSFUL = 'ADD_FAMILY_MEMEMBER_SUCCESSFUL'
export const FETCH_FAMILY_DETAILS = 'FETCH_FAMILY_DETAILS'
export const MEDICALL_DETALS_FETCH_SUCCESSFUL = 'MEDICALL_DETALS_FETCH_SUCCESSFUL'
export const EMERGENCY_CONTACTS_FETCH_SUCCESSFUL = 'EMERGENCY_CONTACTS_FETCH_SUCCESSFUL'

export const GET_TOTAL_APPLICATIONS = 'GET_TOTAL_APPLICATIONS'
export const GET_TOTAL_ADMISSIONS = 'GET_TOTAL_ADMISSIONS'
export const GET_TOTAL_REJECTIONS = 'GET_TOTAL_REJECTIONS'
export const GET_SCHOOL_ADMISSIONS_SUCCESSFUL = 'GET_SCHOOL_ADMISSIONS_SUCCESSFUL'

