import { Link, useLocation } from "react-router-dom"


const ProfileNav=()=>{
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return(
        <ul className="nav">
        <li className="nav-item">
            <Link className={splitLocation[2] === "general" ? "nav-link active" : "nav-link profile-link"} aria-current="page" to="/profile/general">General</Link>
        </li>
        <li className="nav-item">
            <Link className={splitLocation[2] === "family" ? "nav-link active" : "nav-link profile-link"} to="/profile/family">Family members</Link>
        </li>   
        <li className="nav-item">
            <Link className={splitLocation[2] === "medical" ? "nav-link active" : "nav-link profile-link"} to="/profile/medical">Medical details</Link>
        </li>
        <li className="nav-item">
            <Link className={splitLocation[2] === "emergencycontacts" ? "nav-link active" : "nav-link profile-link"} to="/profile/emergencycontacts">Emergency contacts</Link>
        </li>
        <li className="nav-item">
            <Link className="nav-link profile-link" to="#">Eating Patterns</Link>
        </li>
        <li className="nav-item">
            <Link className="nav-link profile-link" to="#">Pickup</Link>
        </li>
    
    </ul>
    )
}

export default ProfileNav
