import {Fragment} from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import Header from "../../components/Header"
import BarChart from "../../components/Chart";
import SchPieChart from "../Charts/pie";

const Dashboard =(props)=>{
    const {currentUser} = props
    return(
    <Fragment>
        <div className='wrapper  sidebar-mini layout-fixed'>
            <Header/>
            <Sidebar currentUser={currentUser}/>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-6">
                            <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-center text-center">
                                                <h3 className="card-title text-center">School admissions</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="position-relative mb-4 panel-height text-center empty_panel">
                                                      <i className="fa fa-folder fa-5x"></i>
                                                      <h5><small className="text-muted">You currently have no school admissions.Go to applications and start applying.</small></h5>
                                                </div>   
                                            </div>                                        
                                        </div>
                             </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-center ">
                                                <h3 className="card-title">My application selections</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex ">
                                            <div className="position-relative mb-4 panel-height text-center empty_panel">
                                                      <i className="fab fa-adn fa-5x"></i>
                                                      <h5><small className="text-muted">You have not made any applications. Apply to schools in their categories.</small></h5>
                                                </div>                                    
                                                
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-center text-center">
                                                <h3 className="card-title text-center">Schooladmin statistics</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-4 stat_panel_height stat_panel">
                                                {/* <div className="position-relative mb-4 panel-height text-center empty_panel"> */}
                                                      <BarChart/>
                                                {/* </div>    */}
                                            </div>                                        
                                        </div>
                          
                          
                             </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-center text-center">
                                                <h3 className="card-title text-center">My application statistics</h3>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="pie_panel">
                                                <div className="mb-4 panel-height text-center">
                                                      <SchPieChart/>
                                                </div>   
                                            </div>                                        
                                        </div>
                          
                          
                             </div>
                            </div>
                        </div>                        
                    </div>
                </div>

            </div>
            <Footer/>
        </div>
    </Fragment>
    )
}
export default Dashboard
