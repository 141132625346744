import { Component } from "react";
import { connect } from "react-redux";
import ClassCourseForm from "../../containers/Course/classCourseForm";
import { addClass } from "../../actions/institutionActions";
import { getSchoolClasses } from "../../actions/institutionActions";

class Classes extends Component {
  constructor(props) {
    super(props);
    // Initialize state
    this.state = {
      classes: [[]],
      name: '', // Assuming you have a field called 'name' for your form
    };
  }

  componentDidMount() {
    const { getSchoolClasses } = this.props;
    getSchoolClasses();
  }

  // Use getDerivedStateFromProps to update state based on new props
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.classes !== prevState.classes) {
      return { classes: nextProps.classes }; // Update the classes state
    }
    return null; // No state change
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { addClass } = this.props;
    const classData = this.state;
    await addClass(classData); // Wait for addClass to complete
    await getSchoolClasses();   // Refresh the classes state

  };

  render() {
    return (
      <ClassCourseForm
        onChange={this.onChange}
        handleSubmit={this.handleSubmit}
        classes={this.state.classes} // Use the updated classes state
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.schools,
});

export default connect(mapStateToProps, { addClass, getSchoolClasses })(Classes);
