import React, { Component } from "react";
import LeanerAdmissionsContainer from "../../containers/LearnerAdmissionsContainer";
import { defaultLeanerAdmissionColumns } from "../../config/constants";
import { getLearnerAdmissions } from "../../actions/applicationActions";
import { connect } from "react-redux";

class LearnerAdmissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admissionList: [], // Initialize as an empty array
        };
    }

    componentDidMount() {
        const { getLearnerAdmissions } = this.props;
        getLearnerAdmissions();
    }

    componentDidUpdate(prevProps) {
        // Check if admissionList has changed
        if (prevProps.admissionList !== this.props.admissionList) {
            this.setState({
                admissionList: this.props.admissionList,
            });
        }
    }

    render() {
        const { admissionList } = this.state;
        return (
            <LeanerAdmissionsContainer
                defaultMyapplicationColumns={defaultLeanerAdmissionColumns}
                rows={admissionList} // Pass the admissionList as rows
            />
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.schools,
});

const schoolAdmins = connect(mapStateToProps, { getLearnerAdmissions })(LearnerAdmissions);
export default schoolAdmins;
