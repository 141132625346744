import React from "react";

import {Redirect, Route} from 'react-router-dom'
import { Authenticate } from ".";
import {user_type} from "../../config/constants"



export const SchoolRoute = ({component: Component, ...restProps})=>(
    <Route
        {...restProps}
        render={props =>(
            
            Authenticate.isAuthenticated() === true && user_type ==='school'
                ? <Component {...props} />
                :(
                    <Redirect to={{
                        pathname: '/',
                        state: {from: props.location}
                    }}
                    />
                )
        )}
    />
)
