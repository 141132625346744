import React from "react";

import jwtDecode from 'jwt-decode'
import {Redirect, Route} from 'react-router-dom'
import {logoutUser} from '../../actions/actionCreators'
import { user_type } from "../../config/constants";


export const Authenticate={
    isAuthenticated(){
        try{
            let token = localStorage.getItem('auth_token')
            token = jwtDecode(token)
            return !!token
        }catch (e) {
            localStorage.clear()
            return false

        }
    },
    logout(dispatch){
        localStorage.clear()
        dispatch(logoutUser())
    }
}

export const PrivateRoute = ({component: Component, ...restProps})=>(
    <Route
        {...restProps}
        render={props =>(
            Authenticate.isAuthenticated() === true && user_type ==='learner'
                ? <Component {...props} />
                :(
                    <Redirect to={{
                        pathname: '/',
                        state: {from: props.location}
                    }}
                    />
                )
        )}
    />
)
