import React, {Component} from 'react'
import { connect } from 'react-redux'
import SchoolDashboardCont from "../../containers/Dashboard/schoolDashboard"
import { 
    getTotalApplications, getTotalAdmissions, 
    getTotalDeclined
} from "../../actions/institutionActions"

class SchoolDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: '',
            totalApplications: 0,
            totalAdmissions:0,
            totalRejections:0
        };
    }

    componentDidMount() {
        const { getTotalApplications,getTotalAdmissions, getTotalDeclined } = this.props;
        getTotalApplications();
        getTotalAdmissions();
        getTotalDeclined();


    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.currentUser !== prevState.currentUser || 
            nextProps.totalApplications !== prevState.totalApplications ||
            nextProps.totalAdmissions !== prevState.totalAdmissions ||
            nextProps.totalRejections !== prevState.totalRejections

            ) {
            return {
                currentUser: nextProps.currentUser,
                totalApplications: nextProps.totalApplications,
                totalAdmissions: nextProps.totalAdmissions,
                totalRejections: nextProps.totalRejections

            };
        }
        return null;
    }


    render() {
        return (
            <SchoolDashboardCont 
                currentUser={this.state.currentUser}
                totalApplications={this.state.totalApplications}
                totalAdmissions = {this.state.totalAdmissions}
                totalRejections = {this.state.totalRejections}
            />
        );
    }
}

const mapStateToProps = state => ({
    ...state.user,
    ...state.schools
});

const SchContent = connect(
    mapStateToProps, {
         getTotalApplications, 
         getTotalAdmissions,
         getTotalDeclined
         }
    )(SchoolDashboard);

export default SchContent;

