import {applicationFeeSuccess, getLearnerApplicationsSuccessful, getSchoolAdmissionsListSuccessful } from "./actionCreators"
import {applicationURL, admissionFeeURL, learnerApplicationsURL, schoolApplicationsURL, schoolAdmissionsURL, leanerAdmissionsURL } from "../config/constants"
import {toast} from "react-toastify";
import axiosInstance from "../config/axiosInstance"
import { requestLoading } from "./requestLoading"


export const getAdmissionFee=(selectedSchool)=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.get(admissionFeeURL+'/'+selectedSchool).then(response=>{
        dispatch(requestLoading(false))    
        dispatch(applicationFeeSuccess(response.data))

    }).catch(error=>{
        dispatch(requestLoading(false))

    })

}

export const makeApplication = (applicationData, reportFile) => dispatch => {
    dispatch(requestLoading(true));

    // Set school ID and class ID from local storage
    applicationData.school_id = localStorage.getItem('school_id');
    applicationData.school_class_id = localStorage.getItem('school_class_id');

    const formData = new FormData();
    formData.append('document', reportFile); // Append the uploaded file
    formData.append('school_id', applicationData.school_id);
    formData.append('school_class_id', applicationData.school_class_id);

    // Send the formData instead of applicationData
    axiosInstance.post(applicationURL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Important for file uploads
        },
    }).then(response => {
        dispatch(requestLoading(false));
        toast.success(response.data.message);
    }).catch(error => { 
        dispatch(requestLoading(false));
        toast.error(error.response.data.message);
    });
};

export const getLearnerApplications=()=>dispatch=>{
    dispatch(requestLoading(true))
    axiosInstance.get(learnerApplicationsURL).then(response=>{
        dispatch(requestLoading(false))
        dispatch(getLearnerApplicationsSuccessful(response.data))
    }).catch(error=>{
        dispatch(requestLoading(false))
    })
}

export const getSchoolApplications = () => dispatch => {
    dispatch(requestLoading(true));
    axiosInstance.get(schoolApplicationsURL).then(response => {
        // Log the original response data
        console.log(response.data);

        // Format the response data
        const formattedApplications = response.data.map(application => {
            const date = new Date(application[1]); // Parse the application date
            
            return [
                application[0],  // ID
                date.toLocaleString('en-US', {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                }),  // Formatted application date
                application[2],  // Learner Name
                application[3],  // Class Name
                application[4]   // Report URL
            ];
        });

        console.log(formattedApplications); // Log the formatted applications
        dispatch(requestLoading(false));
        dispatch(getLearnerApplicationsSuccessful(formattedApplications)); // Dispatch the formatted applications
    }).catch(error => {
        dispatch(requestLoading(false));
        console.error("Error fetching school applications:", error); // Log the error
    });
};

export const getSchoolAdmissions = () => (dispatch) => {
    dispatch(requestLoading(true));

    axiosInstance.get(schoolAdmissionsURL)
        .then(response => {
            dispatch(requestLoading(false));
            
            // Get admissions data
            const admissions = response?.data?.admissions || [];  // Safely access admissions

            // Convert admissions to an array of arrays
            const formattedAdmissions = admissions.map(admission => {
                const date = new Date(admission.application_date); // Parse the application date
            
                return [
                    admission.id,
                    date.toLocaleString('en-US', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    }),  // Formatted application date
                    `${admission?.first_name || ''} ${admission?.last_name || ''}`,
                    admission?.class_name,
                    admission?.report_url || null
                ];
            });
            dispatch(getSchoolAdmissionsListSuccessful(formattedAdmissions)); // Dispatch the formatted admissions
        })
        .catch(error => {
            console.error('Error fetching school admissions:', error); // Log the error
            dispatch(requestLoading(false));
        });
};


export const getLearnerAdmissions = () => (dispatch) => {
    dispatch(requestLoading(true));

    axiosInstance.get(leanerAdmissionsURL)
        .then(response => {
            dispatch(requestLoading(false));
            
            // Get admissions data
            console.log(response)
            const admissions = response?.data?.admissions || [];  // Safely access admissions

            // Convert admissions to an array of arrays
            const formattedAdmissions = admissions.map(admission => {
                const date = new Date(admission.application_date); // Parse the application date
                return [
                    date.toLocaleString('en-US', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    }),  // Formatted application date
                    admission?.school_name,
                    admission?.class_name,
                    admission?.application_status
                ];
            });

            dispatch(getSchoolAdmissionsListSuccessful(formattedAdmissions)); // Dispatch the formatted admissions
        })
        .catch(error => {
            console.error('Error fetching school admissions:', error); // Log the error
            dispatch(requestLoading(false));
            // Optionally dispatch an error action if needed
        });
};
