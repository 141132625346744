const Footer=()=>{
    return(
        <footer className="main-footer">
            Copyright &copy; {new Date().getFullYear()} School Admissions
            <div className="float-right d-none d-sm-inline-block">
                <a href="https://dcode.co.ug"> Terms of service</a>                 <a href="https://dcode.co.ug">Read about our privacy policy</a>

            </div>
        </footer>
    )

}
export default Footer