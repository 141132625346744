import React, {Component} from 'react'
import { connect } from 'react-redux';
import DashboardPanel from "../../containers/Dashboard";

class Dashboard extends Component{

    constructor(props){
        super(props)
        this.state={
            currentUser:''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.currentUser !== prevState.currentUser){


            return { currentUser: nextProps.currentUser }
        }
        return null
    }


    render() {
        return(
            <DashboardPanel currentUser={this.state.currentUser}/>
        )
    }
}

const mapStateToProps =state=>({
    ...state.user
})

const DashContent = connect(mapStateToProps,{})(Dashboard)

export default DashContent
