import { Component } from "react";
import { connect } from "react-redux";
import BasicInfoContainer from "../../containers/Profile/basicInfo";
import {updateBasicInfo, getBasicInfo} from "../../actions/profileActions"


class BasicInfo extends Component{
    constructor(props){
        super(props)
        this.state={
            first_name:'',
            last_name:'',
            email:'',
            gender:'',
            phone_number:'',
            dob:''

        }
    }
    componentDidMount(){
        const {getBasicInfo}= this.props
        getBasicInfo()
    }

    handleSubmit = event=>{
        event.preventDefault()
        const {updateBasicInfo, getBasicInfo}= this.props
        const {dob,gender,phone_number}= this.state
        const profileData = {
            profile:{
                dob,gender,phone_number
            }            
        }
        updateBasicInfo(profileData)
        getBasicInfo()
    }

    handleChange=event=>{
        const {name,value}=event.target
        this.setState({[name]:value})
    }

    render(){
        const {first_name, last_name,email,dob,gender,phone_number}= this.state
        return(
            <BasicInfoContainer 
                first_name={first_name}
                last_name={last_name}
                email={email}
                dob={dob}
                gender={gender}
                phone_number={phone_number}
                handleSubmitOnModal={this.handleSubmit}
                handleChangeOnModal={this.handleChange}
            />
        )
    }
}

const mapStateToProps = state =>({
    ...state.user
})

const basicInfo = connect (mapStateToProps, {updateBasicInfo, getBasicInfo})(BasicInfo)
export default basicInfo