import { Fragment } from "react"
import { Link } from "react-router-dom"
import Header from "../Header"
import Footer from "../Footer"
import Sidebar from "../Sidebar"
import ProfileNav from "./ProfileNav"
import NewFamilyProfile from "../../components/Profile/NewFamilyMember"
import Table from "../Table"
import { defaultFamilyColumns } from "../../config/constants"

const FamilyContainer =(props)=>{
    const {familyDetails
    } = props
    return(
        <Fragment>
            <div className='wrapper  sidebar-mini layout-fixed'>
                <Header/>
                <Sidebar/>
                <div className="content-wrapper">
                 <div className="content-header">
                    <div className="container-fluid">
                      <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Profile</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">profile</li>
                                </ol>
                            </div>
                        </div>

                        <div className="row mb-2">

                        <ProfileNav />

                        </div>

                        <div className="row mb-2">
                            <div className="col-sm-12 card">
                                <div className="card-header">
                                    <div className="row mb-2">
                                        <div className="col-sm-6 profile-panel-padding">
                                        <h5>Learner's Family details</h5>
                                        </div>
                                        <div className="col-sm-6"><button className="btn btn-success float-right" data-toggle="modal" data-target="#familyProfile">Add family members</button></div>
                                    </div>
                                </div>
                                <div className="card-body padding-bottom">
                                <div className="row mb-2 card-scroll  padding_top">
                                    <div className="col-sm-12">
                                            {
                                                familyDetails.length===0?( 
                                                    <div className="empty-panel">
                                                        <h5>Add Family members</h5>
                                                         <div className="school-list-empty"> Our professional school management system delivers effective communication, control, reporting and insight across education institutions. Click add family members to update your profile  ...</div>

                                                    </div>
                                                ):(<Table cols={defaultFamilyColumns} dbRows={familyDetails} tableType="advanced"/>)
                                            }
                                    </div>

                                </div>

                                <NewFamilyProfile/>

                                </div>
                            </div>

                        </div>
                    </div>
                  </div>
                </div> 
                <Footer/>
            </div>    
            

        </Fragment>
    )
}

export default FamilyContainer