import axios from 'axios'
import {baseURL, timeout, token} from './constants'
const headers = token?{
    'Content-Type': 'application/json',
    'Authorization':`Token ${token}`

}:{
    'Content-Type': 'application/json'
}

const axiosInstance= axios.create({
    baseURL,
    timeout,
    headers
})
export default axiosInstance
