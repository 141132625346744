import {Fragment} from "react";
import {Link, useLocation} from "react-router-dom";
import brandLogoWhite from "../../static/images/brandLogoWhite.png"
const AdminSideBar=(props)=>{
    const {org_name, username} = props
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    
    return(
        <Fragment>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div className="brand-link">
                <img src={brandLogoWhite} alt="mukisa lms Logo" className="brand-image"
                     style={{opacity: .8}}/>
                <span className="brand-text font-weight-light"> &nbsp;</span>
            </div>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="info">
                        <h5 className="company-name"> {org_name || username?(org_name || username):('Dcode Dynamics')}</h5>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li className="nav-item">
                            <Link to="/admin-portal" className={splitLocation[1] === "admin-portal" ? "nav-link active" : "nav-link"}>
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Admin dashboard
                                </p>
                            </Link>
                        </li>
                        <li className="nav-item menu-open">
                            <Link to="#" className={splitLocation[1] === "institution" ? "nav-link active" : "nav-link"}>
                                <i className="nav-icon fa fa-school"></i>                                    <p>
                                    Institutions
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                        <li className="nav-item">
                            <Link to="/institution" className="nav-link">
                                <i className="nav-icon fa fa-child"></i>                                    <p>
                                    Add Institutions
                                </p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link">
                                <i className="nav-icon fas fa-school"></i>
                                <p>
                                    View Institutions
                                </p>
                            </a>
                        </li>
                     
                      </ul>
                    </li>
                        <li className="nav-item">
                            <a href="pages/gallery.html" className="nav-link">
                                <i className="nav-icon far fa-image"></i>
                                <p>
                                    Pay schools
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="pages/gallery.html" className="nav-link">
                                <i className="nav-icon fab fa-resolving"></i>                                    <p>
                                    Admission Results
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link">
                                <i className="nav-icon far fa-clock"></i>                                    <p>
                                    Dates & Deadlines
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="" className="nav-link">
                                <i className="nav-icon fas fa-money-check"></i>                                    <p>
                                    Transaction Details
                                </p>
                            </a>
                        </li>

                        <li className="nav-item">
                            <a href="" className="nav-link">
                                <i className="nav-icon fas fa-life-ring"></i>                                    <p>
                                    Support Center
                                </p>
                            </a>
                        </li>

                    </ul>
                </nav>
            </div>
        </aside>
    </Fragment>
    )

}
export default AdminSideBar