import { EMERGENCY_CONTACTS_FETCH_SUCCESSFUL } from "../actions/types"


const initialState ={
    learnerEmergencyContacts:[]
}

const  emergencyContactsReducer=(state=initialState, action)=>{
    switch(action.type){
        case EMERGENCY_CONTACTS_FETCH_SUCCESSFUL:
            return{
                ...state,
                learnerEmergencyContacts:action.emergencyContacts
            }
        default:
            return state

    }
}

export default emergencyContactsReducer