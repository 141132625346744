import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'

const BarChart = () => {
  const barChartData = {
    labels: ["October", "November", "December"],
    datasets: [
      {
        data: [8137119, 9431691, 10266674],
        label: "school applications",
        borderColor: "#007bff",
        backgroundColor: "rgb(0, 123, 255)",
        fill: true
      },
      {
        data: [1216410, 1371390, 1477380],
        label: "school admissions",
        borderColor: "#28a745",
        backgroundColor: "rgb(40, 167, 69)",
        fill: true
      }
    ]
  };

  const barChart = (
    <Bar
      type="bar"
      options={{
        title: {
          display: true,
          text: "Institutions with the highest application number",
          fontSize: 15
        },
        legend: {
          display: false, //Is the legend shown?
          position: "top" //Position of the legend.
        }
      }}
      data={barChartData}
    />
  );
  return barChart;
};

export default BarChart;