import React, { Component } from "react";
import SchoolAdmissionsContainer from "../../containers/SchoolAdmissionsContainer";
import { defaultSchoolapplicationsColumns } from "../../config/constants";
import { getSchoolAdmissions } from "../../actions/applicationActions";
import { connect } from "react-redux";

class SchoolAdmissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admissionList: [], // Initialize as an empty array
        };
    }

    componentDidMount() {
        const { getSchoolAdmissions } = this.props;
        getSchoolAdmissions();
    }

    componentDidUpdate(prevProps) {
        // Check if admissionList has changed
        if (prevProps.admissionList !== this.props.admissionList) {
            this.setState({
                admissionList: this.props.admissionList,
            });
        }
    }

    render() {
        const { admissionList } = this.state;
        return (
            <SchoolAdmissionsContainer
                defaultMyapplicationColumns={defaultSchoolapplicationsColumns}
                rows={admissionList} // Pass the admissionList as rows
            />
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.schools,
});

const schoolAdmins = connect(mapStateToProps, { getSchoolAdmissions })(SchoolAdmissions);
export default schoolAdmins;
