import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

const PageLoader=(props)=>(
    props.loading?(
        <div className="spinner-wrapper">
            <div className="spinner-border text-primary spinner-centered" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    ):('')
)

PageLoader.propTypes={
    loading:PropTypes.bool.isRequired
}
const mapStateToProps=state=>({
    ...state.requestLoading
})

export default connect(mapStateToProps)(PageLoader)
