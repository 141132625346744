import TableBody from "./tableBody"
import TableHeader from "./tableHeader"

const Table=(props)=>{
    const {cols,dbRows, tableType}=props
    return(
        <table id="example" className="table custom-table table-hover table-striped table-bordered ">
            <TableHeader cols={cols}/>
            <TableBody dbRows={dbRows} tableType={tableType}/>
        </table>
    )
}
export default Table