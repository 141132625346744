import { Component } from "react";
import { connect } from "react-redux";
import {getMedicalInfo, addMedicalDetails} from "../../actions/profileActions"
import MedicalContainer from "../../containers/Profile/medicalInfo";

class Medical extends Component{

    constructor(props){
        super(props)
        this.state={ 
            sick:'',
            sickness_type:'N/A',
            learners_physcian:'N/A',
            physcian_tel:'N/A',
            physcian_address:'N/A',
            postal_code:'N/A',
            allergies:'N/A',
            learnerMedicalDetails:[[]],
        }
    }

    componentDidMount(){
        const {getMedicalInfo} =this.props
        getMedicalInfo()
    }

    static getDerivedStateFromProps(nextProps){
        const {learnerMedicalDetails} = nextProps
        return{learnerMedicalDetails}
    }

    
    handleChangeOnModal = event =>{
        const {name, value} = event.target
        this.setState({[name]:value})
    }

    handleSubmit= event=>{
        event.preventDefault()
        const {addMedicalDetails} =this.props
        const {sick,sickness_type,learners_physcian,
            physcian_tel, physcian_address,
            postal_code, allergies}=this.state
        const medicalData={
            medical:{
                sick,
                sickness_type,
                learners_physcian: learners_physcian,
                physcian_tel: physcian_tel,
                physcian_address: physcian_address,
                postal_code: postal_code,
                allergies: allergies


               },

        }
        addMedicalDetails(medicalData)
}

    render(){
        const { sick,learnerMedicalDetails} = this.state
        return(
            <MedicalContainer

                sick={sick}
                dbRows={learnerMedicalDetails}
                handleChangeOnModal={this.handleChangeOnModal}
                handleSubmit={this.handleSubmit}
            />
        )
    }
}

const mapStateToProps = state =>({
    ...state.medical
})
const MedicalProfile = connect(mapStateToProps, {getMedicalInfo, addMedicalDetails})(Medical)
export default MedicalProfile
