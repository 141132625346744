import {combineReducers} from "redux";
import userReducer from "./userReducer";
import loadingReducer from "./loadingReducer";
import institutionReducer from "./institutionReducer";
import schoolsReducer from "./schoolsReducer";
import applicationsReducer from "./applicationsReducer";
import familyReducer from "./familyReducer"
import medicalReducer from "./medicalReducer";
import emergencyContactsReducer from "./emergencyContactsReducer";

const rootReducer = combineReducers({
    user: userReducer,
    requestLoading: loadingReducer,
    institution: institutionReducer,
    schools:schoolsReducer,
    learnerApplication: applicationsReducer,
    family:familyReducer,
    medical:medicalReducer,
    emergencyContacts: emergencyContactsReducer
})
export default rootReducer