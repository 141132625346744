import { Component } from 'react'
import { connect } from 'react-redux'
import SchHeader from '../../containers/Header'
class Header extends Component{
    constructor(props){
        super(props)
        this.state={
            currentUser:''
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.currentUser !== prevState.currentUser){


            return { currentUser: nextProps.currentUser }
        }
        return null
    }


    render(){
        return(
            <SchHeader currentUser={this.state.currentUser}/>
        )
    }
}
const mapStateToProps =state=>({
    ...state.user
})

const HeaderContent = connect(mapStateToProps,{})(Header)
export default HeaderContent